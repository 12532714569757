import type { AppProps } from 'next/app'
import { useRouter } from 'next/dist/client/router'
import Script from 'next/script'
import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CartProvider } from 'use-shopping-cart'
import '../app/styles/globals.sass'
import { HydratingRoot, IfHydrated } from '../packages/@necktip/next/hydratation/index'
import { ensureString } from '../utils/assertString'
import { ShoppingProvider } from '../utils/ShoppingContext'

const queryClient = new QueryClient()

function MyApp(props: AppProps) {
	const router = useRouter()

	useEffect(() => {
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init('613671079835484') // facebookPixelId
				ReactPixel.pageView()

				router.events.on('routeChangeComplete', () => {
					ReactPixel.pageView()
				})
			})
	}, [router.events])

	return (
		<HydratingRoot>
			<MyAppInner {...props} />
		</HydratingRoot>
	)
}

function MyAppInner({ Component, pageProps }: AppProps) {
	const content = React.useMemo(
		() => (
			<ShoppingProvider>
				<QueryClientProvider client={queryClient}>
					<Script
						strategy="beforeInteractive"
						src="https://polyfill.io/v3/polyfill.min.js?features=default%2Ces2015%2Ces2016%2Ces2017%2Ces2018"
					/>
					<Component {...pageProps} />
				</QueryClientProvider>
			</ShoppingProvider>
		),
		[Component, pageProps]
	)
	return (
		<IfHydrated
			yes={React.useCallback(
				() => (
					<CartProvider
						mode="payment"
						cartMode="client-only"
						stripe={ensureString(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)}
						successUrl={`${process.env.HOST}/order-completed`}
						cancelUrl={`${process.env.HOST}/order-canceled`}
						currency="CZK"
						allowedCountries={['CZK']}
						billingAddressCollection={true}>
						{content}
					</CartProvider>
				),
				[content]
			)}
			no={React.useCallback(() => content, [content])}
		/>
	)
}
export default MyApp
